var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"xs":"12"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.modify.adSenseAd'))+" "),_c('div',{staticClass:"float-right mr-1"},[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.deleteEntity}},[(!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution())?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('label.button.delete')))]):_c('feather-icon',{attrs:{"icon":"XCircleIcon"}})],1)],1)])],1)],1),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('card.description.modify.adSenseAd'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.name').toLowerCase(),"rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.name').toLowerCase()},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.width')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.width').toLowerCase(),"vid":"width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.width').toLowerCase()},model:{value:(_vm.formData.width),callback:function ($$v) {_vm.$set(_vm.formData, "width", $$v)},expression:"formData.width"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.height')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.height').toLowerCase(),"vid":"height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.height').toLowerCase()},model:{value:(_vm.formData.height),callback:function ($$v) {_vm.$set(_vm.formData, "height", $$v)},expression:"formData.height"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.code')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.code').toLowerCase(),"rules":"required","vid":"adsenseCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.code').toLowerCase()},model:{value:(_vm.formData.adsenseCode),callback:function ($$v) {_vm.$set(_vm.formData, "adsenseCode", $$v)},expression:"formData.adsenseCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.querySelector')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.querySelector').toLowerCase(),"rules":"required","vid":"querySelector"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.querySelector').toLowerCase()},model:{value:(_vm.formData.querySelector),callback:function ($$v) {_vm.$set(_vm.formData, "querySelector", $$v)},expression:"formData.querySelector"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.position')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.position').toLowerCase(),"rules":"required","vid":"relativeToQuerySelector"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.positionOptions},model:{value:(_vm.formData.relativeToQuerySelector),callback:function ($$v) {_vm.$set(_vm.formData, "relativeToQuerySelector", $$v)},expression:"formData.relativeToQuerySelector"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.status')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.status').toLowerCase(),"rules":"required","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.statusOptions},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1),_c('help-modal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }