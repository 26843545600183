<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.adSenseAd') }}
            <div class="float-right mr-1">
              <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteEntity"
              >
                <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()"
                      class="text-nowrap">{{ $t('label.button.delete') }}</span>
                <feather-icon v-else icon="XCircleIcon"/>
              </b-button>
            </div>
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.adSenseAd') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                    :label="$t('form.name')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.name').toLowerCase()"
                      rules="required"
                      vid="name"
                  >
                    <b-form-input
                        v-model="formData.name"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.name').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.width')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.width').toLowerCase()"
                      vid="width"
                  >
                    <b-form-input
                        v-model="formData.width"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.width').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.height')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.height').toLowerCase()"
                      vid="height"
                  >
                    <b-form-input
                        v-model="formData.height"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.height').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    :label="$t('form.code')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.code').toLowerCase()"
                      rules="required"
                      vid="adsenseCode"
                  >
                    <b-form-textarea
                        v-model="formData.adsenseCode"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.code').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    :label="$t('form.querySelector')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.querySelector').toLowerCase()"
                      rules="required"
                      vid="querySelector"
                  >
                    <b-form-input
                        v-model="formData.querySelector"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.querySelector').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.position')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.position').toLowerCase()"
                      rules="required"
                      vid="relativeToQuerySelector"
                  >
                    <v-select
                        v-model="formData.relativeToQuerySelector"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="positionOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.status')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.status').toLowerCase()"
                      rules="required"
                      vid="status"
                  >
                    <v-select
                        v-model="formData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="statusOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
      <help-modal/>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BLink,
  BOverlay,
  BRow
} from 'bootstrap-vue'
import {required,} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import editor from "@/views/pages/_components/_helper/editor.vue";

export default {
  components: {
    BLink,
    BFormTextarea,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    cancelAndGOTOPreviousPage,
    editor,
  },
  data() {
    return {
      role: 'adSenseAd',
      formData: {
        id: null,
        name: '',
        adsenseCode: '',
        width: '',
        height: '',
        querySelector: '',
        relativeToQuerySelector: '',
        status: '',
      },
      positionOptions: [],
      statusOptions: [],
      loading: false,
      required,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.$store.dispatch('fetchAdSenseAd', this.$route.params.id).then(response => {
        this.formData = response.data.entity
        this.formData.id = this.$route.params.id
        this.formData.adsenseCode = response.data.entity.adsense_code
        this.formData.querySelector = response.data.entity.query_selector

        this.$store.dispatch('fetchAdSenseAdStatuses').then(sResponse => {
          this.statusOptions = sResponse.data.values

          if (this.statusOptions.length) {
            Object.keys(this.statusOptions).forEach(key => {
              if (this.statusOptions[key].value === response.data.entity.status) {
                this.formData.status = this.statusOptions[key]
              }
            })
          }
        })

        this.$store.dispatch('fetchAdSenseAdPositions').then(pResponse => {
          this.positionOptions = pResponse.data.values

          if (this.positionOptions.length) {
            Object.keys(this.positionOptions).forEach(key => {
              if (this.positionOptions[key].value === response.data.entity.relative_to_query_selector) {
                this.formData.relativeToQuerySelector = this.positionOptions[key]
              }
            })
          }
        })
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.relativeToQuerySelector = this.formData.relativeToQuerySelector.value
          fd.status = this.formData.status.value

          this.$store.dispatch('updateAdSenseAd', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.initAreaCode()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    deleteEntity() {
      this.$bvModal
          .msgBoxConfirm(this.$t('confirm.message.delete'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
            cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const id = this.$route.params.id
              this.$store.dispatch('deleteAdSenseAd', id).then(() => {
                this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))

                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'adSenseAds'})
                    : this.$router.go(-1)
              })
            }
          })
    },
  }
}
</script>
